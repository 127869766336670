import React, { useState, useEffect } from 'react';
import RantForm from './components/RantForm';
import RantList from './components/RantList';
import { Container } from 'react-bootstrap';
import { supabase } from './supabaseClient';
import './App.css';

function App() {
  const [rants, setRants] = useState([]);

  useEffect(() => {
    const fetchRants = async () => {
      const { data, error } = await supabase
        .from('rants')
        .select('*')
        .order('timestamp', { ascending: false });
      if (error) console.error('Error fetching rants:', error);
      else setRants(data);
    };

    fetchRants();
  }, []);

  const handleRantSubmit = (newRant) => {
    setRants([newRant, ...rants]); // Only add the new rant, not the JSON object
  };

  return (
    <Container className="my-4">
      <h1 className="text-center mb-4">Anonymous Job Interview Rants</h1>
      <p className="text-center">Vent your frustrations about job interviews here!</p>
      <RantForm onRantSubmit={handleRantSubmit} />
      <RantList rants={rants} setRants={setRants} />
    </Container>
  );
}

export default App;
