import React, { useState, useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { PersonFill, HandThumbsUp, HandThumbsDown } from 'react-bootstrap-icons';
import { formatDistanceToNow, parseISO } from 'date-fns';
import { supabase } from '../supabaseClient';

function RantList({ rants, setRants }) {
  const [votedRants, setVotedRants] = useState(
    JSON.parse(localStorage.getItem('votedRants')) || {}
  );

  useEffect(() => {
    // Save the votedRants state to localStorage whenever it changes
    localStorage.setItem('votedRants', JSON.stringify(votedRants));
  }, [votedRants]);

  const handleVote = async (rantId, type) => {
    const currentRant = rants.find((rant) => rant.id === rantId);

    if (!currentRant) return;

    let updatedUpvotes = currentRant.upvotes;
    let updatedDownvotes = currentRant.downvotes;
    let newVotedRants = { ...votedRants };

    if (newVotedRants[rantId]) {
      if (newVotedRants[rantId] === type) {
        if (type === 'upvote') updatedUpvotes--;
        else updatedDownvotes--;
        delete newVotedRants[rantId];
      } else {
        if (type === 'upvote') {
          updatedUpvotes++;
          updatedDownvotes--;
        } else {
          updatedUpvotes--;
          updatedDownvotes++;
        }
        newVotedRants[rantId] = type;
      }
    } else {
      if (type === 'upvote') updatedUpvotes++;
      else updatedDownvotes++;
      newVotedRants[rantId] = type;
    }

    const { error } = await supabase
      .from('rants')
      .update({
        upvotes: updatedUpvotes,
        downvotes: updatedDownvotes,
      })
      .eq('id', rantId);

    if (!error) {
      setRants((prevRants) =>
        prevRants.map((rant) =>
          rant.id === rantId
            ? { ...rant, upvotes: updatedUpvotes, downvotes: updatedDownvotes }
            : rant
        )
      );
      setVotedRants(newVotedRants);
    } else {
      console.error('Error updating votes:', error);
    }
  };

  return (
    <div>
      {rants.map((rant) => {
        const timestamp = parseISO(rant.timestamp);
        const voteScore = rant.upvotes - rant.downvotes;

        return (
          <Card key={rant.id} className="mb-2 shadow-sm border-0">
            <Card.Body className="p-4">
              <Card.Text className="mb-3">{rant.text}</Card.Text>
              <div className="text-muted d-flex align-items-center mb-3">
                <PersonFill className="me-2" />
                <span>
                  Posted by Anonymous{' '}
                  {formatDistanceToNow(timestamp, {
                    addSuffix: true,
                  })}
                </span>
              </div>
              <div className="d-flex align-items-center">
                <Button
                  variant={
                    votedRants[rant.id] === 'upvote'
                      ? 'success'
                      : 'outline-success'
                  }
                  size="sm"
                  className="me-2"
                  onClick={() => handleVote(rant.id, 'upvote')}
                >
                  <HandThumbsUp className="me-1" />
                  {rant.upvotes}
                </Button>
                <Button
                  variant={
                    votedRants[rant.id] === 'downvote'
                      ? 'danger'
                      : 'outline-danger'
                  }
                  size="sm"
                  onClick={() => handleVote(rant.id, 'downvote')}
                >
                  <HandThumbsDown className="me-1" />
                  {rant.downvotes}
                </Button>
                <span className="ms-3">
                  Score: {voteScore > 0 ? `+${voteScore}` : voteScore}
                </span>
              </div>
            </Card.Body>
          </Card>
        );
      })}
    </div>
  );
}

export default RantList;
